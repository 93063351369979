import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component:()=>import('@/views/index'),
    children:[
      {
        name:'home-page',
        path:'/',
        component:()=>import('@/views/home-page')
      },
      {
        path:'/cooperation-case',
        component:()=>import('@/views/cooperation-case'),
        children:[
          {
            path:'/',
            component:()=>import('@/views/cooperation-case/case-list'),
          },
          {
            path:'/detail-js',
            component:()=>import('@/views/cooperation-case/jiangshan-machinery'),
          },
          {
            path:'/grid-member',
            component:()=>import('@/views/cooperation-case/grid-member'),
          },
        ]
      },
      {
        path:'/product-display',
        component:()=>import('@/views/product-display'),
        children:[
          {
            path:'/',
            component:()=>import('@/views/product-display/product-list'),
          },
          {
            path:'/datian-clairvoyant',
            component:()=>import('@/views/product-display/datian-clairvoyant'),
          },
          // {
          //   path:'/grid-member',
          //   component:()=>import('@/views/cooperation-case/grid-member'),
          // },
        ]
      },
      {
        name:'about-us',
        path:'/about-us',
        component:()=>import('@/views/about-us')
      },
      {
        name:'contact-us',
        path:'/contact-us',
        component:()=>import('@/views/contact-us')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
